const d = "radcloud", f = "https://radcloud.socsci.ru.nl/api/v1/upload/?format=json";
let h = 2;
const r = {
  method: "POST",
  headers: {
    "Content-Type": "application/json"
    // Add any additional headers if required
  },
  body: null,
  // Additional options
  mode: "cors"
  // Enable CORS
}, m = {
  timeout: 3e4
  // Set a timeout of 30 seconds
}, l = (o, s = {}, n) => new Promise((i, t) => {
  fetch(o, s).then((e) => {
    if (e.ok)
      return e;
    if (n > 0)
      return l(o, s, n - 1);
    throw new Error("Failed after retry");
  }).then((e) => {
    i(e);
  }).catch((e) => {
    if (e instanceof TypeError && e.message === "Failed to fetch")
      console.error("Network error. Please check your internet connection."), t(e);
    else if (e instanceof TypeError && e.message === "NetworkError when attempting to fetch resource.")
      console.error("Network error. Unable to fetch the resource."), t(e);
    else if (e instanceof DOMException && e.code === DOMException.ABORT_ERR)
      console.error("Request aborted due to timeout."), t(e);
    else if (e instanceof TypeError && e.message === "Failed to fetch")
      console.error("CORS issue. Make sure the server allows requests from your domain."), t(e);
    else if (e instanceof TypeError && e.message.includes("The operation is insecure"))
      console.error("SSL/TLS error. Check the certificate and connection security."), t(e);
    else if (e instanceof TypeError && e.message.includes("Failed to execute 'json' on 'Response'"))
      console.error("Error in parsing JSON response. Possibly invalid JSON format."), t(e);
    else if (e instanceof TypeError && e.message.includes("Failed to execute 'text' on 'Response'"))
      console.error("Error in reading response text."), t(e);
    else if (e instanceof TypeError && e.message.includes("Failed to set the 'body' property"))
      console.error("Error in setting the request body."), t(e);
    else if (e instanceof TypeError && e.message.includes("Request aborted"))
      console.error("Request aborted. Check if the server is running and reachable."), t(e);
    else if (e instanceof TypeError && e.message.includes("Failed to execute 'clone' on 'Response'"))
      console.error("Error in cloning the response."), t(e);
    else
      return console.error("Error:", e.message), console.warn("The request may be stalled. Retrying..."), l(o, s, n - 1);
  });
});
function y(o, s) {
  const n = JSON.stringify({
    api_key: o,
    data: s
  });
  r.body = n;
  const i = new AbortController(), t = i.signal, e = setTimeout(() => {
    i.abort();
  }, m.timeout);
  return new Promise((c, u) => {
    l(f, { ...r, signal: t }, h).then((a) => {
      if (clearTimeout(e), !(a != null && a.ok))
        throw new Error(`Request failed with status: ${a.status}`);
      return a.json();
    }).then((a) => {
      c(a);
    }).catch((a) => u(a));
  });
}
function p(o, s, n) {
  const i = d + "&" + o + "&" + n;
  localStorage.setItem(i, JSON.stringify(s));
}
function g(o, s, n) {
  return new Promise(function(i, t) {
    let e = (/* @__PURE__ */ new Date()).valueOf();
    try {
      p(s, n, e);
    } catch {
      console.log("Failed saving data locally");
    }
    try {
      typeof o > "u" || o == "" ? (console.error("Empty or undefined api_key"), t({
        status: null,
        message: "Empty or undefined api_key"
      })) : y(o, n).then((c) => i(c)).catch((c) => t(c));
    } catch {
      console.error("Failed saving data remotely");
    }
  });
}
export {
  g as saveData
};
