import { CommandSystem, CommandSystemDonate, CommandSystemExit, isCommandSystemDonate, isCommandSystemExit } from './framework/types/commands'
import { Bridge } from './framework/types/modules'
import { saveData }  from './datahandler'

const API_KEY = "cWc!M_zz3kk;s{&D!fR_8L7!utfSV3M5]R5Kw]Ab5|CaqN_*HsgjSVeswD0AWV}:"

export default class FakeBridge implements Bridge {
  send (command: CommandSystem): void {
    if (isCommandSystemDonate(command)) {
      this.handleDonation(command)
    } else if (isCommandSystemExit(command)) {
      this.handleExit(command)
    } else {
      console.log('[FakeBridge] received unknown command: ' + JSON.stringify(command))
    }
  }

  handleDonation (command: CommandSystemDonate): void {
    saveData(API_KEY, 0, command)
    console.log(`[FakeBridge] received donation: ${command.key}=${command.json_string}`)
  }

  handleExit (command: CommandSystemExit): void {
    console.log(`[FakeBridge] received exit: ${command.code}=${command.info}`)
  }
}
